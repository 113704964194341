import React from 'react'

import Layout from '../../../components/Layout'

export default class AdminNavbar extends React.Component {
  render() {
    return (
      <Layout noFooter active="messages">
        <iframe
          title="brand_infogram"
          src="https://infogram.com/messages-and-experiments-1h8j4xle7nz84mv?live"
          className="full-page-iframe singleHeader"
        />
      </Layout>
    )
  }
}
